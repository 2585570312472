<template>
  <section class="q-intro">
    <div class="q-intro__content">
      <h1 class="page__heading">Welcome</h1>
      <div class="page__content post-content">
        <p class="post-content__highlighted post-content__highlighted--small">
          You are about to get information on a quotation from one of our clients. <br />Firstly, just allow us to show
          you our flow so you know what you are up to.
        </p>
      </div>
      <div class="q-intro__flow">
        <div class="q-intro__flow-step">
          <div class="q-intro__flow-icon q-intro__flow-icon--large" v-html="svg.iconIdea"></div>
          <div class="q-intro__flow-info">
            <strong class="q-intro__flow-title">Submit to FindSourcing</strong>
            <p class="q-intro__flow-text">Brand register request in FindSourcing engine.</p>
          </div>
        </div>
        <div class="q-intro__flow-step">
          <div class="q-intro__flow-icon" v-html="svg.iconSearch"></div>
          <div class="q-intro__flow-info">
            <strong class="q-intro__flow-title">Global sourcing</strong>
            <p class="q-intro__flow-text">
              FindSourcing initiates sourcing among suppliers and contacts according to request.
            </p>
          </div>
        </div>
        <div class="q-intro__flow-step">
          <div class="q-intro__flow-icon q-intro__flow-icon--large" v-html="svg.iconInformation"></div>
          <div class="q-intro__flow-info">
            <strong class="q-intro__flow-title">Supplier submit offers</strong>
            <p class="q-intro__flow-text">
              Suppliers submit their offers. FindSourcing curates offers presentable for buyer.
            </p>
          </div>
        </div>
        <div class="q-intro__flow-step">
          <div class="q-intro__flow-icon" v-html="svg.iconPay"></div>
          <div class="q-intro__flow-info">
            <strong class="q-intro__flow-title">Tollgate</strong>
            <p class="q-intro__flow-text">
              FindSourcing charges €49-499 if buyer wants to proceed with more in-depth discussions. Progressive pricing
              based on potential order value.
            </p>
          </div>
        </div>
        <div class="q-intro__flow-step">
          <div class="q-intro__flow-icon q-intro__flow-icon--large" v-html="svg.iconFeedback"></div>
          <div class="q-intro__flow-info">
            <strong class="q-intro__flow-title">Buyer feedback</strong>
            <p class="q-intro__flow-text">
              Buyer gives input on each option. On positive respons communication will be handed over, buyer –
              manufacturer. On negative reply an explanation will be submitted to improve for next time.
            </p>
          </div>
        </div>
      </div>
      <div class="q-intro__footer">
        <router-link
          :to="{ name: 'quotationInfo', params: { id: $route.params.id } }"
          class="old-form__button q-intro__button"
          >Proceed to quotation request</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
// SVG
import iconFeedback from '../img/qi-feedback.svg';
import iconIdea from '../img/qi-idea.svg';
import iconInformation from '../img/qi-information.svg';
import iconPay from '../img/qi-pay.svg';
import iconSearch from '../img/qi-search.svg';

export default {
  load({ store, route }) {
    store.head.title = `FindSourcing – Source Suppliers`;
  },
  data() {
    return {
      svg: {
        iconFeedback,
        iconIdea,
        iconInformation,
        iconPay,
        iconSearch,
      },
    };
  },
};
</script>
