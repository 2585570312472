var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "q-intro" }, [
    _c("div", { staticClass: "q-intro__content" }, [
      _c("h1", { staticClass: "page__heading" }, [_vm._v("Welcome")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "q-intro__flow" }, [
        _c("div", { staticClass: "q-intro__flow-step" }, [
          _c("div", {
            staticClass: "q-intro__flow-icon q-intro__flow-icon--large",
            domProps: { innerHTML: _vm._s(_vm.svg.iconIdea) },
          }),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-intro__flow-step" }, [
          _c("div", {
            staticClass: "q-intro__flow-icon",
            domProps: { innerHTML: _vm._s(_vm.svg.iconSearch) },
          }),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-intro__flow-step" }, [
          _c("div", {
            staticClass: "q-intro__flow-icon q-intro__flow-icon--large",
            domProps: { innerHTML: _vm._s(_vm.svg.iconInformation) },
          }),
          _vm._v(" "),
          _vm._m(3),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-intro__flow-step" }, [
          _c("div", {
            staticClass: "q-intro__flow-icon",
            domProps: { innerHTML: _vm._s(_vm.svg.iconPay) },
          }),
          _vm._v(" "),
          _vm._m(4),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-intro__flow-step" }, [
          _c("div", {
            staticClass: "q-intro__flow-icon q-intro__flow-icon--large",
            domProps: { innerHTML: _vm._s(_vm.svg.iconFeedback) },
          }),
          _vm._v(" "),
          _vm._m(5),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "q-intro__footer" },
        [
          _c(
            "router-link",
            {
              staticClass: "old-form__button q-intro__button",
              attrs: {
                to: {
                  name: "quotationInfo",
                  params: { id: _vm.$route.params.id },
                },
              },
            },
            [_vm._v("Proceed to quotation request")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__content post-content" }, [
      _c(
        "p",
        {
          staticClass:
            "post-content__highlighted post-content__highlighted--small",
        },
        [
          _vm._v(
            "\n        You are about to get information on a quotation from one of our clients. "
          ),
          _c("br"),
          _vm._v(
            "Firstly, just allow us to show\n        you our flow so you know what you are up to.\n      "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "q-intro__flow-info" }, [
      _c("strong", { staticClass: "q-intro__flow-title" }, [
        _vm._v("Submit to FindSourcing"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "q-intro__flow-text" }, [
        _vm._v("Brand register request in FindSourcing engine."),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "q-intro__flow-info" }, [
      _c("strong", { staticClass: "q-intro__flow-title" }, [
        _vm._v("Global sourcing"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "q-intro__flow-text" }, [
        _vm._v(
          "\n            FindSourcing initiates sourcing among suppliers and contacts according to request.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "q-intro__flow-info" }, [
      _c("strong", { staticClass: "q-intro__flow-title" }, [
        _vm._v("Supplier submit offers"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "q-intro__flow-text" }, [
        _vm._v(
          "\n            Suppliers submit their offers. FindSourcing curates offers presentable for buyer.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "q-intro__flow-info" }, [
      _c("strong", { staticClass: "q-intro__flow-title" }, [
        _vm._v("Tollgate"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "q-intro__flow-text" }, [
        _vm._v(
          "\n            FindSourcing charges €49-499 if buyer wants to proceed with more in-depth discussions. Progressive pricing\n            based on potential order value.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "q-intro__flow-info" }, [
      _c("strong", { staticClass: "q-intro__flow-title" }, [
        _vm._v("Buyer feedback"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "q-intro__flow-text" }, [
        _vm._v(
          "\n            Buyer gives input on each option. On positive respons communication will be handed over, buyer –\n            manufacturer. On negative reply an explanation will be submitted to improve for next time.\n          "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }